import React from 'react'
import Header from '../component/Header'
import Nav from '../component/Nav'
import Images from '../Images/about_one.png'
import Image1 from '../Images/about_two.jpg'
import Image2 from '../Images/about_three.jpg'
import Footer from '../component/Footer'

const About = () => {
  return (
    <>
      <Header />
      <Nav />

      <section>
        <div className="discoverRigCarrier">
          <div className="aboutUs_section wow fadeInUp" data-wow-duration="1s">
            <h4 className='discoverirgfont'>ABOUT US</h4>            
            <div className="row">
              <div className="col-lg-8 mt-2">
                    <div className='wow fadeInUp' data-wow-duration="1.5s">
                      <p className="weMeet_paragraph">We meets the developmental needs of various sectors</p>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>

            
      <section>
        <div className="rigFormText marginAlignment wow fadeInUp" data-wow-duration="1.5s">
            <p className='rigText'>Rigforms Nig Limited is an experienced indigenous privately owned company with over a decade of operation. Rigforms Nig Limited is one of the leading oil and gas servicing companies with proven experience in Engineering, Logistics, Procurement, and Construction. In 2004 the company was incorporated.</p>
            <p className='rigText mb-2'>We are offering combine domain knowledge, best practices, safe and environmentally sound site operations, innovative technologies and high-quality support with the objective to help our customers increase oilfield efficiency, improve productivity, lower finding and production costs.</p>
          </div>
      </section>



      <div className="grayColorDivCarrier mt-5 wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
        <section>
            <div className="ourAimtext">
              <div>
                 <p>Our aim, is to provide realtime linkage with world-class experts and knowledge, delivering the latest and best problem-solving capabilities anywhere and anytime.</p>
            </div>
              </div>
             
        </section>
      </div>
      
      <section>
        <div className="rigFormText wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
          <div className="discoverRigCarrier">
            <p className='rigText'>Rigforms Nig Limited  is managed by dexterously selected, highly intelligent, accomplished, experienced, well motivated and reputable professionals who have distinguished themselves in their various fields of endeavour. Their wealth of experience provides us the tactical advantage needed to stand and excel in this dynamic industry.</p>
            <p className='rigText'>Our team focuses on being the best business and social partner to create value for the clients we work for and the regions we work in.</p>
          </div>
        </div>
      </section>
 
      <section>
        <div className="aboutfullImageDiv1 wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
          <img src={Images} alt="img" />
        </div>
      </section>

      <section> 
        <div className="discoverRigCarrier wow fadeInUp" data-wow-duration="1s">          
          <div className="row mt-2 mb-1">
          <h4 className='discoverirgfont'>What we stand for</h4>  
          <div className="col-lg-6 mt-2  order-2">
            <div className="fullImageDiv wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
              <img src={Image1} alt="img" />
            </div>
          </div>

          <div className="col-lg-6 mt-2 order-1">
            <div className="missionDivCarrier wow fadeInUp" data-wow-duration="1.7s" data-wow-offset="80">
              <div className="missionHeadi">
                <h2>MISSION</h2>
                  <p>Our mission is to develop exceptional values to our clients in the areas of engineering, procurement and project management with regard to the environmental protection, health and safety utilizing technology and local resources to the maximum thereby enhancing Nigeria Content Development.</p>
              </div>
            </div>
          </div>
          </div>
                    
          <div className="row mt-5 mb-4"> 
            
          <div className="col-lg-6 mt-2">
              <div className="fullImageDiv wow fadeInUp" data-wow-duration="1.7s" data-wow-offset="80">
                <img src={Image2} alt="img" />
              </div>
            </div>

            <div className="col-lg-6 order-1 mt-2">
              <div className="missionDivCarrier wow fadeInUp" data-wow-duration="1.9s" data-wow-offset="80">
                <div className="missionHeadi">
                  <h2>VISION</h2>
                  
                  <p>Our vision is to become a world-class company that meets the development needs of various sectors in the Nigerian economy and World at large.</p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default About