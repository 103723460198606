import React, { useState } from 'react'
import Header from '../component/Header'
import Nav from '../component/Nav'
import Footer from '../component/Footer'
import { Form } from 'react-bootstrap'

const Contact = () => {

    const [userData, setUserData] = useState({
      name: '',
      email: '',
      message: '',
    })   
  
    const changeInputhandler = (event) => {
      const { name, value } = event.target;
      setUserData({
        ...userData,
        [name]: value,
      });
    };
  
    const registerUser = async (e) => {
      console.log(userData)
    }
  

  return (
    <>

    <Header />
    <Nav />  
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8 mb-5 mt-5">
            
       <section>
            <div className="contact_us_flex">
              {/* <div className="contactUs_img_carrier">
                <div className="contactUs_img wow animate__fadeInUp" data-wow-duration="1.8s">
                  <img src={Avatar} className='contact_img' alt="Contact img" />
                </div>
              </div> */}

              <div className="contactUs_form_carrier">
                <div className="contactUs_form " data-wow-duration="2.5s"  style={{zIndex:"-5",}} data-wow-offset="80">
                  <h2 className='inTouch ' >Let's Get In Touch</h2>
                  <p  className='wow animate__fadeInUp' >Or just reach out manually on <a href="mailto:help.grajahmic@gmail.com">
                  info@rigform.com</a></p>


                  <Form onSubmit={registerUser}>
                  <Form.Group className='mb-3 wow animate__fadeInUp' data-wow-duration="1.5s" controlId='exampleForm.ControlInput1'>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type='text'
                      name="name"  // Ensure this matches the key in the state
                      value={userData.name}
                      onChange={changeInputhandler}
                      placeholder='Enter your full name'
                    />
                  </Form.Group>


                  <Form.Group className='mb-3 wow animate__fadeInUp' data-wow-duration="4s" controlId='exampleForm.ControlInput1'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type='email'
                      name="email"  // Ensure this matches the key in the state
                      value={userData.email}
                      onChange={changeInputhandler}
                      className='removeBg'
                      placeholder='Enter your email address'
                    />
                  </Form.Group>

                  <Form.Group className='mb-3 wow animate__fadeInUp' data-wow-duration="4s" controlId='exampleForm.ControlInput1'>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type='email'
                      name="email"  // Ensure this matches the key in the state
                      value={userData.email}
                      onChange={changeInputhandler}
                      className='removeBg'
                      placeholder='Enter your Phone Number'
                    />
                  </Form.Group>
                   
                  <Form.Group className='mb-3 wow animate__fadeInUp' data-wow-duration="4.5s" controlId='exampleForm.ControlTextarea1'>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={5}
                      name="message"  // Ensure this matches the key in the state
                      value={userData.message}
                      onChange={changeInputhandler}
                      placeholder='Write your message'
                    />
                  </Form.Group>
                    <div className="btnDiv mt-3">
                    <button type='submit' className='btn mainColor btn-primary wow animate__fadeInUp' data-wow-duration="5s">Submit Form</button>
                    </div>
                  </Form>

                </div>
              </div>
            </div>
      </section>
        </div>
        <div className="col-lg-2"></div>
      </div> 



    <Footer />
      
    </>
  )
}

export default Contact