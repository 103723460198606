import React from 'react'
import Header from '../../component/Header'
import Nav from '../../component/Nav'
import Images from '../../Images/services_five.jpeg'
import Footer from '../../component/Footer'
import { useNavigate } from "react-router-dom";

const IndustrialCleaningPage = () => {
  const navigate = useNavigate();

  return (
    <>
        <Header />
        <Nav />

        
      <div className='fullWidth'>
        <div className='row  mb-5'>
          <div className='col-lg-6'>
            <div className="aboutUs_section detailHeaders mt-5 wow fadeInUp" data-wow-duration="1s">
              <h4 className='discoverirgfont'>SERVICES</h4>
              <div className="row ">
                <div className="col-lg-12">
                  <div className='wow fadeInUp' data-wow-duration="1.5s">
                    <p className="weMeet_paragraph">Industrial Cleaning and Plant Maintenance Services</p>
                  </div>
                </div>
              </div>
              <div className="rigFormText marginAlignment  wow fadeInUp" data-wow-duration="1.7s">
                <p className='text'>Rigforms Nigeria Ltd offers maintenance and cleaning services for oil and gas industrial customers to maintain equipment and facilities. Work is performed either continuously or during shutdowns. We manages both the cleaning operations at the facility and the disposal and treatment of hazardous waste. </p>
              </div>
              <button onClick={() => {navigate("/Contact-us")}}   className='completionContactButtton mb-3'>Contact Us</button>
            </div>
          </div>


          <div className='col-lg-6'>
            <div className="fullImageDiv30  wow fadeInUp" data-wow-duration="1.7s" >
              <img src={Images} alt="img" />
            </div>
          </div>

        </div>
      </div>
              
      <section>
        <div className="sectionGap wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
            <p className='rigText'>We work on all types of site and have specialists in the oil and gas industries. Oil networks and plants, industrial sites and hydrocarbon tanks require regular maintenance. Rigforms offers operations with specializes equipment, specially trained personnel, and guaranteed compliance with the current regulatory recommendations.</p>

            <p className='rigText'>The broad range of cleaning services we provide includes:</p>
            


                        
          <div className="col-lg-9 mt-2">
              <div className="usefulLinkDetail wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
                  <ul>
                    <li className='detailList'>Chemical Cleaning.</li>
                    <li className='detailList'>Heat Exchanger Cleaning.</li>
                    <li className='detailList'>High Pressure Water Jetting.</li>
                    <li className='detailList'>Decommissioning & Demolition.</li>
                    <li className='detailList'>Column Tower & Vessel Cleaning.</li>
                  </ul>
              </div>
          </div>  
            
          </div>
      </section>

      <Footer />
    </>
  )
}

export default IndustrialCleaningPage