import React from 'react'
import Logo from '../Images/logo 1.png'
import { Link } from 'react-router-dom'

const Nav = () => {
  return (
    <>

     <div className="wrapNav">
        <div className="contain-nav" >
          <nav className="navbar navbar-expand-lg navbar-light "  data-wow-duration="1s" id="navbarNav1">
              <div className="container">
                  
              <a href="./" className="navbar-brand" id="logo-m">          
                  <img src={Logo} className="d-inline-block align-top navLogo" alt=""/>     
              </a>

                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">

                      <ul className="navbar-nav  mx-auto  me-5 nm-r">
                          <li className="nav-item">
                          <Link to="/"className="nav-link waves-effect waves-light active-nav text-uppercase">Home</Link>
                          </li>
                          <li className="nav-item">
                          <Link to="/About-us" className="nav-link waves-effect waves-light text-uppercase">About Us</Link>
                          </li>
                          <li className="nav-item">
                          <Link to="/Our-Services" className="nav-link waves-effect waves-light text-uppercase">Our Services</Link>
                          </li>
                          <li className="nav-item">
                          <Link to="/HSE" className="nav-link waves-effect waves-light text-uppercase">HSE</Link>
                          </li>
                          <li className="nav-item">
                          <Link to="/Contact-us" className="nav-link waves-effect waves-light text-uppercase">Contact Us</Link>
                          </li>
                      </ul>
                  </div>
              </div>
          </nav>
        </div>
      </div>   	
   


    </>
  )
}

export default Nav