import React from 'react'
import Header from '../../component/Header'
import Nav from '../../component/Nav'
import Images from '../../Images/servcies_six.png'
import Footer from '../../component/Footer'
import { useNavigate } from "react-router-dom";

const EngineeringProcurementPage = () => {
  const navigate = useNavigate();
  return (
    <>
        <Header />
        <Nav />

                
      <div className='fullWidth'>
        <div className='row mb-5'>
          <div className='col-lg-6'>
            <div className="aboutUs_section detailHeaders mt-5 wow fadeInUp" data-wow-duration="1s">
              <h4 className='discoverirgfont'>SERVICES</h4>
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <p className="weMeet_paragraph wow fadeInUp" data-wow-duration="1.5s">Engineering, Procurement & Construction Services</p>
                  </div>
                </div>
              </div>
              <div className="rigFormText marginAlignment wow fadeInUp " data-wow-duration="1.7s">
                <p className='text'>Rigforms Nig Limited are known for our ability to provide the topmost level of engineering,procurement, construction, installation and commissioning services to clients for their complex project investments</p>
              </div>
              <button onClick={() => {navigate("/Contact-us")}}  className='completionContactButtton mb-3'>Contact Us</button>
            </div>
          </div>


          <div className='col-lg-6'>
            <div className="fullImageDiv30 wow fadeInUp" data-wow-duration="1.7s">
              <img src={Images} alt="img" />
            </div>
          </div>

        </div>
      </div>

      <section>
        <div className="mt-4 mb-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
            <p className='rigText'>Rigforms Nig Limited provides excellence turnkey services for the Oil and Gasindustry. Our exceptional ability to deliver seamless integration of engineering,procurement and construction services throughout all stages of a projectallows us to ensure our customers:</p>

                        
          <div className="col-lg-9 mt-2">
              <div className="usefulLinkDetail wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
                  <ul>
                    <li className='detailList'>Save time.</li>
                    <li className='detailList'>Fishing services.</li>
                    <li className='detailList'>Lower project cost.</li>
                    <li className='detailList'>Reduced project schedule.</li>
                    <li className='detailList'>Complete project safety and quality.</li>
                    <li className='detailList '>Minimize operational upsets and downtime.</li>
                  </ul>
              </div>
          </div>  
            
          </div>
      </section>

      <Footer />
    </>
  )
}

export default EngineeringProcurementPage