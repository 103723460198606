import React, { useEffect } from 'react'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebook, FaXTwitter } from 'react-icons/fa6'
import WOW from 'wowjs';

const Header = () => {
  
  useEffect(() => {
    new WOW.WOW({
    live: false
        }).init();
    },[]);

  useEffect(() => {
    new WOW.WOW({
    live: false
        }).init();
    },[]);
  return (
    <div className='HwrapNav'>
      <div className="slashCarrier " data-wow-duration="1s">
        <div className="emptydiv">
          {/* <div className="bannerCarrier">
            <div className="banner-content">
              <span className="phone-number">07040005553</span>
              <span className="email">info@rigform.com</span>
            </div>
          </div> */}
        
        </div>
        <div className="banner">
          <div className="banner-content">
            <span className="phone-number">+2348037064961</span>
            <span className="email">Info@rigforms.com</span>
          </div>
          <div className="banner-content headerFOnts">
            <FaFacebook />
            <AiFillInstagram />
            <FaXTwitter />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header