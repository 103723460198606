import React from 'react'
import Header from '../../component/Header'
import Nav from '../../component/Nav'
import Images from '../../Images/services_two.png'
import Footer from '../../component/Footer'
import { useNavigate } from "react-router-dom";

const PiggingServicesPage = () => {
  const navigate = useNavigate();
  return (
    <>
        <Header />
        <Nav />

                        
      <div className='fullWidth'>
        <div className='row mb-5'>
          <div className='col-lg-6'>
            <div className="aboutUs_section detailHeaders welservicesMarginTop wow fadeInUp" data-wow-duration="1s">
              <h4 className='discoverirgfont'>SERVICES</h4>
              <div className="row ">
                <div className="col-lg-12 ">
                  <div className=' wow fadeInUp' data-wow-duration="1.5s">
                    <p className="weMeet_paragraph">Pigging Services</p>
                  </div>
                </div>
              </div>
              <div className="rigFormText marginAlignment wow fadeInUp" data-wow-duration="1.7s">
                <p className='text'>Pipelines are safe, effective way of transporting products of high value, such as oil and gas. They are however, prone to variables such as corrosion and deposits that can reduce flow rates and cause costly failures.</p>
              </div>
              <button onClick={() => {navigate("/Contact-us")}}  className='completionContactButtton mb-3'>Contact Us</button>
            </div>
          </div>


          <div className='col-lg-6'>
            <div className="fullImageDiv20 wow fadeInUp" data-wow-duration="1.7s">
              <img src={Images} alt="img" />
            </div>
          </div>

        </div>
      </div>

      <section>
        <div className="mt-4 mb-5 wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
            <p className='rigText mb-5'>Presently, cleaning pigs are used to eliminate deposits to maintain pipeline integrity, and then intelligent in-line inspection pigs are used to detect defects. Our intelligent pigging solutions allow your pipeline to be regularly monitored for defect development, minimizing costs and reducing risks to your service.</p>
            
          </div>
      </section>

      <Footer />
    </>
  )
}

export default PiggingServicesPage