import React from 'react'
import Header from '../component/Header'
import Nav from '../component/Nav'
import { FaRegArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Footer from '../component/Footer'

const Services = () => {
  return (
    <>

      <Header />
      <Nav />
      
      <section>
        <div className="discoverRigCarrier">
          <div className="aboutUs_section wow fadeInUp" data-wow-duration="1s">
            <h4 className='discoverirgfont'>OUR SERVICES</h4>            
            <div className="row">
              <div className="col-lg-8 mt-2">
                    <div className='wow fadeInUp' data-wow-duration="1.5s" >
                      <p className="weMeet_paragraph">Expert Project Design & Precise Estimation for Success.</p>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section>
        <div className="rigFormText marginAlignment wow fadeInUp" data-wow-duration="1.5s">
            <p className='rigText'>Rigforms Nig Limited has taken a customized, single-sourced approached to its services by providing customers a wide range of options and allowing them to pick and choose those services that are most beneficial for their organization</p>
            <p className='rigText'>Each service is tailored to solve a specific customer problem. We design and estimate the project, gaining a detailed understanding of the work scope and critical path. We observe, listen and understand the business needs before recommending a solution that will ultimately fulfil the customer's objective.</p>
          </div>
      </section>

      <section>
                                        
      <div className="row mt-5 mb-5">

      <div className="col-lg-4 mb-3 mt-3">
        <div className='wow fadeInUp hover_effect' data-wow-duration="1.5s" data-wow-offset="80">
          <Link to='/well_intervention'>
            <div className='imageDiv1'>
                <div className="imageDiv2layer">
                    <div className="displayFlex">
                        <div className="wellInterventionTextCarrier">
                            <div className="wellInterventionText weldivpt">
                                <h3 className="wellText">
                                Well Intervention Services
                                </h3>
                                <p className="wellparagraph">Rigforms Nig Limited provides a wide range of well intervention services and
                                experience to extend the life of producing wells by improving performance or providing access to stranded.</p>
                            </div>
                        </div>
                        <div className="wellInterventionFontCarrier">
                            <div className="fontCarrier"><FaRegArrowAltCircleRight /></div>
                        </div>
                    </div>
                </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="col-lg-4 mb-3 mt-3">
        <div className='wow fadeInUp hover_effect' data-wow-duration="1.7s" data-wow-offset="80">
        <Link to='/pigging_service'>
            <div className='imageDiv2'>
                <div className="imageDiv2layer">
                    <div className="displayFlex">
                        <div className="wellInterventionTextCarrier">
                            <div className="wellInterventionText weldivpt">
                                <h3 className="wellText">Pigging Services
                                </h3>
                                <p className="wellparagraph">Pipelines are safe, effective way of transporting products of high value, such as oil and gas. They are however, prone to variables such as corrosion and deposits that can reduce flow rates and cause costly failures.</p>
                            </div>
                        </div>
                        <div className="wellInterventionFontCarrier">
                            <div className="fontCarrier"><FaRegArrowAltCircleRight /></div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
        </div>
      </div>

      <div className="col-lg-4 mb-3 mt-3">
        <div className='wow fadeInUp hover_effect' data-wow-duration="1.5s" data-wow-offset="90">
        <Link to='/pumping_service'>
            <div className='imageDiv3'>
                <div className="imageDiv2layer">
                    <div className="displayFlex">
                        <div className="wellInterventionTextCarrier">
                            <div className="wellInterventionText weldivpt">
                                <h3 className="wellText">Pumping Services</h3>
                                <p className="wellparagraph">Rigforms Nigeria Ltd offers a wide range of solutions for pumping services and equipment spanning various industries. With a qualified team of experts and service specialists, we can provide you with a total solution and support for your fluid moving processes.  </p>
                            </div>
                        </div>
                        <div className="wellInterventionFontCarrier">
                            <div className="fontCarrier"><FaRegArrowAltCircleRight /></div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
        </div>
      </div>     
      
      <div className="col-lg-4 mt-5 mb-5">
        <div className='wow fadeInUp hover_effect' data-wow-duration="1.5s" data-wow-offset="90">
        <Link to="/completion_service">
            <div className='imageDiv4'>
                <div className="imageDiv2layer">
                    <div className="displayFlex">
                        <div className="wellInterventionTextCarrier">
                            <div className="wellInterventionText weldivpt">
                                <h3 className="wellText">Completion Services</h3>
                                <p className="wellparagraph">We provide solutions for every situation, including techn ically challengingenvironments requiring integrated systems and project management.</p>
                            </div>
                        </div>
                        <div className="wellInterventionFontCarrier">
                            <div className="fontCarrier"><FaRegArrowAltCircleRight /></div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
        </div>
      </div>
                  
      
      <div className="col-lg-4 mt-5 mb-5">
        <div className='wow fadeInUp hover_effect' data-wow-duration="1.7s" data-wow-offset="90">
        <Link to="/industrial_cleaning">
            <div className='imageDiv5'>
                <div className="imageDiv2layer">
                    <div className="displayFlex">
                        <div className="wellInterventionTextCarrier">
                            <div className="wellInterventionText weldivpt">
                                <h3 className="wellText">Industrial Cleaning and Plant Maintenance Services</h3>
                                <p className="wellparagraph">Rigforms Nigeria Ltd offers maintenance and cleaning services for oil and gas 
                                industrial customers to maintain equipment and facilities. Work is performed either continuously or during shutdowns. </p>
                            </div>
                        </div>
                        <div className="wellInterventionFontCarrier">
                            <div className="fontCarrier"><FaRegArrowAltCircleRight /></div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
        </div>
      </div>            
      
      <div className="col-lg-4 mt-5 mb-5">
        <div  className='wow fadeInUp hover_effect' data-wow-duration="1.9s" data-wow-offset="90">
        <Link to="/engineering_procurement">
            <div className='imageDiv6'>
                <div className="imageDiv2layer">
                    <div className="displayFlex">
                        <div className="wellInterventionTextCarrier">
                            <div className="wellInterventionText weldivpt">
                                <h3 className="wellText">Engineering, Procurement & Construction Services</h3>
                                <p className="wellparagraph">Rigforms Nig Limited provides excellence turnkey services for the Oil and Gas industry.</p>
                            </div>
                        </div>
                        <div className="wellInterventionFontCarrier">
                            <div className="fontCarrier"><FaRegArrowAltCircleRight /></div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
        </div>
      </div>

      </div>
      </section>
      
      <Footer />
    </>
  )
}

export default Services