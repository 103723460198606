import React from 'react'
import Header from '../component/Header'
import Nav from '../component/Nav'
import Footer from '../component/Footer'
import Images from '../Images/hse.jpg'

const Hse = () => {
  return (
    <div>
        < Header />
        <Nav />

        
      <section>
        <div className="discoverRigCarrier">
          <div className="aboutUs_section wow fadeInUp" data-wow-duration="1s">
            <h4 className='discoverirgfont'>HSE</h4>            
            <div className="row">
              <div className="col-lg-8 mt-2">
                    <div className='wow fadeInUp' data-wow-duration="1.5s">
                      <p className="weMeet_paragraph">Health, Safety & Environment</p>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>

            
      <section>
        <div className="rigFormText marginAlignment wow fadeInUp" data-wow-duration="1.5s">
            <p className='rigText'>There is a need for us to live in societies w here we can co -exist with other life forms and nature-much of our socio-economic and scientific activities threaten the ecological balance in our world. Of note is the unflinchin g human demand for land and energy; various activities employed in att aining and exploiting resources; and the development and utility of anti-ecology machinery, products and techniques.</p>
            <p className='rigText mb-2'>Amidst these challenges is the search for cleaner air, save the environment and endangered species.</p>
          </div>
      </section>

      <div className='half_img'>
        <div className="half_img_carrier wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
          <img src={Images} alt="img" />
        </div>
      </div>

                  
      <section>
        <div className="rigFormText margin_buttom marginAlignment wow fadeInUp" data-wow-duration="1.5s">
            <p className='rigText'>Rigforms is developing a fund base: Rigforms Nigeria Li mited Eco-Care Trust - with the aim of providing funding on ecological care. Our dedication to nature preservation and balance is also a public campaign support and corporate pledge.</p>
            <p className='rigText mb-2'>We abide by International HSE sta ndards in business activities and c orporate services on and off oil field service work. It is a necessity and common goal to work for a safe and healthy society . Life is all about bal ance. It is intricate and dynamic.</p>
          </div>
      </section>


        <Footer />
    </div>
  )
}

export default Hse