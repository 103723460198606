import React from 'react'
import Header from '../../component/Header'
import Nav from '../../component/Nav'
import Images from '../../Images/services_three.png'
import Footer from '../../component/Footer'
import { useNavigate } from "react-router-dom";

const PumpingServicesPage = () => {
  const navigate = useNavigate();
  return (
    <>
        <Header />
        <Nav />

                                
      <div className='fullWidth'>
        <div className='row mb-5'>
          <div className='col-lg-6'>
            <div className="aboutUs_section detailHeaders servicesMarginTop wow fadeInUp" data-wow-duration="1s">
              <h4 className='discoverirgfont'>SERVICES</h4>
              <div className="row ">
                <div className="col-lg-12">
                  <div className=' wow fadeInUp' data-wow-duration="1.5s">
                    <p className="weMeet_paragraph">Pumping Services</p>
                  </div>
                </div>
              </div>
              <div className="rigFormText marginAlignment wow fadeInUp" data-wow-duration="1.7s">
                <p className='text'>Rigforms Nigeria Ltd offers a wide range of solutions for pumping services and equipment spanning various industries. With a qualified team of experts and service specialists.</p>
              </div>
              <button onClick={() => {navigate("/Contact-us")}}  className='completionContactButtton mb-3'>Contact Us</button>
            </div>
          </div>


          <div className='col-lg-6'>
            <div className="fullImageDiv20 wow fadeInUp" data-wow-duration="1.7s" >
              <img src={Images} alt="img" />
            </div>
          </div>

        </div>
      </div>
              
      <section>
        <div className="mt-4 mb-5 wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
            <p className='rigText'> we can provide you with a total solution and support for your fluid moving
            processes:</p>

                        
          <div className="col-lg-9 mt-2">
              <div className="usefulLinkDetail wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
                  <ul>
                    <li className='detailList'>Units available for onshore and offshore operations.</li>
                    <li className='detailList'>Fluid tanks are available from 20m3 - 80m3 as operationally required.</li>
                    <li className='detailList'>All units for both onshore and offshore usage are soundproof to 85dba.</li>
                    <li className='detailList'>The fluid pumping units are compact and easy to mover around in limited space.</li>
                    <li className='detailList'>Pumping services can be provided with 500HHP triplex fluid pumps, capable of up to 10.000 psi.</li>
                    <li className='detailList '>Fluid pumping units for onshore operations are provided with a control cabin for operator’s comfort.</li>
                    <li className='detailList '>The units come with fluid storage or mixing tanks as required, flexible solutions design for each well operation.</li>
                  </ul>
              </div>
          </div> 
            
          </div>
      </section>

      <Footer />
    </>
  )
}

export default PumpingServicesPage