import React from 'react'
import Nav from '../component/Nav'
import Header from '../component/Header'
import Image from '../Images/home_one.png'
import { Link } from 'react-router-dom'
import { FaRegArrowAltCircleRight } from 'react-icons/fa'
import { FaRegCircleRight } from 'react-icons/fa6'
import Footer from '../component/Footer'

const Home = () => {
  return (
    <>
        <Header />
        <Nav />

  

  
  <div className="firstimageDiv wow fadeInUp" data-wow-duration="1s">
    <div class="homepage_carrrier">
      <div className='wow fadeInUp' data-wow-duration="1.2s">
        <p class="small-text">WHO WE ARE</p>
        <h1 class="developmental">We meet the developmental<br />needs of various sectors</h1>
        <Link to="/About-us" class=" LearnMoreBTN ">
          Learn More <span class="arrow"><FaRegCircleRight /></span>
        </Link>
      </div>
    </div>
  </div>

  <section>
    <div className="discoverRigCarrier" >
        <div className="discoverRigfoerm wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
            <h4 className='discoverirgfont'>Discover Rigforms</h4>
            <p className='WeSpecialize col-lg-10'>We specialize in providing quality services to companies in the upstream sector of the Nigerian Oil and Gas Industry.</p>
            <p className='thekey col-lg-10'>The key is to provide realtime linkage with world-class experts and knowledge, delivering the latest and best problem-solving capabilities anywhere and anytime.</p>
        </div>
    </div>
  </section>


  <section>
    <div className='row'>
      <div className='col-lg-7'>
        <div className="missionDivCarrier wow fadeInUp" data-wow-duration="1.7s" data-wow-offset="80">
              <div className="weProvideCut">
                <p>We provides cutting edge technology and equipment, highly skilled personnel, and are committed to the development of local content.</p>
              </div>
            </div>
      </div>

      <div className='col-lg-5'>
        <div className='six_img wow fadeInUp' data-wow-duration="1.9s" data-wow-offset="80">
          <img src={Image} alt="img" />
        </div>
      </div>
    </div>
  </section>

  
  <section>
    <div className="discoverRigCarrier wow fadeInUp" data-wow-duration="1.5s">
        <div className="discoverRigfoerm">
            <h4 className='discoverirgfont'>Our Services</h4>
            
            <div className="row mb-1">
              <div className="col-lg-9 mt-2">
                    <div>
                        <p className="paragraph home-serv">Our services are tailored to solve a specific customer problem. We design and estimate the project, gaining a detailed understanding of the work scope and critical path.</p>
                    </div>
              </div>

              <div className="col-lg-3 mt-2">
                <div className='viewMoreCarrier'>
                  <Link to='/Our-Services' className='viewMore'>View More</Link>
                </div>
              </div>

                    <div className="row mt-5 mb-5">
              
                    <div className="col-lg-4 mb-3 mt-3">
                      <div className='wow fadeInUp hover_effect' data-wow-duration="1.5s" data-wow-offset="80">
                        <Link to='/well_intervention'>
                          <div className='imageDiv1'>
                              <div className="imageDiv2layer">
                                  <div className="displayFlex">
                                      <div className="wellInterventionTextCarrier">
                                          <div className="wellInterventionText weldivpt">
                                              <h3 className="wellText">
                                              Well Intervention Services
                                              </h3>
                                              <p className="wellparagraph">Rigforms Nig Limited provides a wide range of well intervention services and
                                              experience to extend the life of producing wells by improving performance or providing access to stranded.</p>
                                          </div>
                                      </div>
                                      <div className="wellInterventionFontCarrier">
                                          <div className="fontCarrier"><FaRegArrowAltCircleRight /></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </Link>
                      </div>
                    </div>
              
                    <div className="col-lg-4 mb-3 mt-3">
                      <div className='wow fadeInUp hover_effect' data-wow-duration="1.7s" data-wow-offset="80">
                      <Link to='/pigging_service'>
                          <div className='imageDiv2'>
                              <div className="imageDiv2layer">
                                  <div className="displayFlex">
                                      <div className="wellInterventionTextCarrier">
                                          <div className="wellInterventionText weldivpt">
                                              <h3 className="wellText ">Pigging Services
                                              </h3>
                                              <p className="wellparagraph">Pipelines are safe, effective way of transporting products of high value, such as oil and gas. They are however, prone to variables such as corrosion and deposits that can reduce flow rates and cause costly failures.</p>
                                          </div>
                                      </div>
                                      <div className="wellInterventionFontCarrier">
                                          <div className="fontCarrier"><FaRegArrowAltCircleRight /></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </Link>
                      </div>
                    </div>
              
                    <div className="col-lg-4 mb-3 mt-3">
                      <div className='wow fadeInUp hover_effect' data-wow-duration="1.5s" data-wow-offset="90">
                      <Link to='/pumping_service'>
                          <div className='imageDiv3'>
                              <div className="imageDiv2layer">
                                  <div className="displayFlex">
                                      <div className="wellInterventionTextCarrier weldivpt">
                                          <div className="wellInterventionText">
                                              <h3 className="wellText">Pumping Services</h3>
                                              <p className="wellparagraph">Rigforms Nigeria Ltd offers a wide range of solutions for pumping services and equipment spanning various industries. With a qualified team of experts and service specialists, we can provide you with a total solution and support for your fluid moving processes.  </p>
                                          </div>
                                      </div>
                                      <div className="wellInterventionFontCarrier">
                                          <div className="fontCarrier"><FaRegArrowAltCircleRight /></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </Link>
                      </div>
                    </div>     

                    </div>


            </div>
        </div>
    </div>
  </section>

  <Footer />




    </>
  )
}

export default Home