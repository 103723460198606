import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <>
        <div className='footerCarrier'>
            <div className="footerImageCarrier">
                <div className="footerTextCarrier">            
                    <div className="row mb-1">
                        <div className="col-lg-3 mt-2">
                            <div className="logoName">
                                <h3>Let’s Talk</h3>
                                <p>about your project.</p>
                            </div>
                        </div>

                        <div className="col-lg-9 mt-2">         
                            <div className="row ">
                                <div className="col-md-3 mt-2">
                                    <div className="UsefulLink">
                                        <h4>Contact Info</h4>
                                        <div className="usefulLinkDetail">
                                            <span>+2348037064961</span>
                                            <span>Info@rigforms.com</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 mt-2">
                                    <div className="UsefulLink">
                                        <h4>Useful Links</h4>
                                        <div className="usefulLinkDetail">
                                            <span><Link to='/Our-Services'>Services</Link></span>
                                            <span><Link to='/About-us'>About us</Link></span>
                                            <span><Link to='/Contact-us'>Contact Us</Link></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 mt-2">
                                    <div className="UsefulLink">
                                        <h4>Office</h4>
                                        <div className="usefulLinkDetail">
                                            <span> Ohia Ekwu-Otulaoba, Rumudun-Were, Shell Location Road Elelenwo, Obio/Akpor Local Govt. Area, Nigeria.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footer