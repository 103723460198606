import React from 'react'
import Header from '../../component/Header'
import Nav from '../../component/Nav'
import Images from '../../Images/services_four.jpg'
import Footer from '../../component/Footer'
import { useNavigate } from "react-router-dom";

const CompletionServicesPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Nav />

      <div className='fullWidth'>
        <div className='row  mb-5'>
          <div className='col-lg-6'>
            <div className="aboutUs_section welservicesMarginTop wow detailHeaders fadeInUp" data-wow-duration="1s">
              <h4 className='discoverirgfont'>SERVICESS</h4>
              <div className="row">
                <div className="col-lg-8 ">
                  <div className=''>
                    <p className="weMeet_paragraph wow fadeInUp" data-wow-duration="1.5s">Completion Services</p>
                  </div>
                </div>
              </div>
              <div className="rigFormText marginAlignment wow fadeInUp" data-wow-duration="1.7s">
                <p className='text'>Rigforms Nig Limited is a proven leader in well completion services , vas texperience, confirmed capabili ties, commit ment to immacula te and safeexecution. We work with our customers o identify their challenges and developcustomized solutions to meet their needs. </p>
              </div>
              <button onClick={() => {navigate("/Contact-us")}} className='completionContactButtton mb-3'>Contact Us</button>
            </div>
          </div>


          <div className='col-lg-6'>
            <div className="fullImageDiv20 wow fadeInUp" data-wow-duration="1.7s" >
              <img src={Images} alt="img" />
            </div>
          </div>

        </div>
      </div>

      <section>
        <div className="mt-4 mb-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
          <p className='rigText'>We provide solutions for every si tuation, including techn ically challenging
            environments requiring integrated systems and project management.</p>

          <p className='rigText'>We have a complete range of services to maxim ize t he perfo rmance of an ycompletion project w hich help repudiate the dangerous effects of hightemperatures and pressures in deepwater wells and corrosive conditions innatural-gas wells.</p>


          <p className='rigText'>We have a complete range of services to maximize the performance of any completion project such as:</p>

          <div className="col-lg-9 mt-2">

            <div className="usefulLinkDetail wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
            <ul>
              <li className='detailList'>Packers.</li>
              <li className='detailList'>Perforating.</li>
              <li className='detailList'>Safety values.</li>
              <li className='detailList'> Isolation values.</li>
              <li className='detailList'>Completion accessories.</li>
              <li className='detailList '>Completion systems, fluid, and tools.</li>
            </ul>

            </div>

          </div>

        </div>
      </section>

      <Footer />
    </>
  )
}

export default CompletionServicesPage