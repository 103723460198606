
import './App.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import Home from './pages/Home';
import About from './pages/About';
import { useEffect } from 'react';
import Contact from './pages/Contact';
import Services from './pages/Services';
import WellInterventionPage from './pages/ServiceDetailPages/WellInterventionPage';
import CompletionServicesPage from './pages/ServiceDetailPages/CompletionServicesPage';
import EngineeringProcurementPage from './pages/ServiceDetailPages/EngineeringProcurementPage';
import IndustrialCleaningPage from './pages/ServiceDetailPages/IndustrialCleaningPage';
import PiggingServicesPage from './pages/ServiceDetailPages/PiggingServicesPage';
import PumpingServicesPage from './pages/ServiceDetailPages/PumpingServicesPage';
import Hse from './pages/Hse';



function App() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} exact/>
            <Route path="/HSE" element={<Hse />} exact/>
            <Route path="/About-us" element={<About />} exact/>
            <Route path="/Contact-us" element={<Contact />} exact/>
            <Route path="/Our-Services" element={<Services />} exact/>
            <Route path="/completion_service" element={<CompletionServicesPage />} exact/>
            <Route path="/engineering_procurement" element={<EngineeringProcurementPage />} exact/>
            <Route path="/industrial_cleaning" element={<IndustrialCleaningPage />} exact/>
            <Route path="/pigging_service" element={<PiggingServicesPage />} exact/>
            <Route path="/pumping_service" element={<PumpingServicesPage />} exact/>
            <Route path="/well_intervention" element={<WellInterventionPage />} exact/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
