import React from 'react'
import Header from '../../component/Header'
import Nav from '../../component/Nav'
import Images from '../../Images/services_one.jpg'
import Footer from '../../component/Footer'
import { useNavigate } from "react-router-dom";

const DetailPage = () => {
  const navigate = useNavigate();
  return (
    <>
        <Header />
        <Nav />

                                        
      <div className='fullWidth'>
        <div className='row  mb-5'>
          <div className='col-lg-6'>
            <div className="aboutUs_section  welservicesMarginTop detailHeaders wow fadeInUp" data-wow-duration="1s">
              <h4 className='discoverirgfont'>SERVICES</h4>
              <div className="row ">
                <div className="col-lg-12">
                  <div className=' wow fadeInUp' data-wow-duration="1.5s">
                    <p className="weMeet_paragraph">Well Intervention <br/>Services</p>
                  </div>
                </div>
              </div>
              <div className="rigFormText marginAlignment wow fadeInUp" data-wow-duration="1.7s">
                <p className='text'>Rigforms Nig Limited provides a wide range of well intervention services and experience to extend the life of producing wells by improving performance or providing access to stranded.</p>
              </div>
              <button onClick={() => {navigate("/Contact-us")}} className='completionContactButtton mb-3'>Contact Us</button>
            </div>
          </div>


          <div className='col-lg-6'>
            <div className="fullImageDiv20 wow fadeInUp" data-wow-duration="1.7s">
              <img src={Images} alt="img" />
            </div>
          </div>

        </div>
      </div>

      <section>
        <div className="mt-4 mb-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
            <p className='rigText'>The scope and depth of our reliable completion solutions allow you to create the ideal conduit from formation to the surface, regardless of your Well type or operating environment. Across cased and open-hole completions, we consistently deliver state-of-the-art services to provide optimized solutions to fit specific problems, providing the greatest return on your field development investment.</p>

            <p className='rigText'>We have a complete range of services to maximize the performance of any completion project such as:</p>
 
            
          <div className="col-lg-9 mt-2">
              <div className="usefulLinkDetail wow fadeInUp" data-wow-duration="1.5s" data-wow-offset="80">
                  <ul>
                    <li className='detailList'>Pumping.</li>
                    <li className='detailList'>Slickline.</li>
                    <li className='detailList'>Stimulation.</li>
                    <li className='detailList'>Coiled tubing.</li>
                    <li className='detailList'>Fishing services.</li>
                    <li className='detailList '>Wireline cased hole intervention service.</li>
                  </ul>
              </div>
          </div>  
              
        </div>
      </section>

      <Footer />
    </>
  )
}

export default DetailPage